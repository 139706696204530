import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"
import ScheduleButton from "../components/schedule.button"
const IndexPage = () => {
  return (
    <Layout pageTitle="Home">
      <StaticImage
        alt="Simtrack1 recording"
        placeholder="blurred"
        style={{ margin: 0 }}
        src="../images/sim_recording.png"
      />
      <p/>
      <h1>Leading the Way in AI and Robotics Consulting Services for Business</h1>
      <p>Welcome to Toast Technology Pty Ltd, your trusted partner in AI and robotics consulting services. We specialize in helping businesses build teams to implement AI and autonomous systems, providing expert guidance and support every step of the way.</p>
      <ScheduleButton />
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo description="Toast Technology Pty Ltd your trusted partner in AI and robotics consulting services." title="Home" />

export default IndexPage
